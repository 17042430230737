import React from "react"
import Type from "../../components/typography/Type"


const Canvas = (props) => {
  
  const title = props.title;
  const introduction = props.introduction;
  const children = props.children;
  
  
	 return (
	  <>	
	  
	  <div className={`bg-slate flex flex-col lg:flex-row`}>

	  	<div className="w-fit">
		  <div className="w-full lg:w-[320px] p-8 ">
		  <Type type="h2">{title}</Type>
		  <Type type="p6">{introduction}</Type>
		 </div>
		  </div>
	  		
	  		
  		<div className={`grow overflow-auto pb-20 pr-20 bg-white h-screen`}>
		  {children}
  	  	</div>	
			
	
	  </div>
	  
	  
	</>
	);
};
  
export default Canvas;
