import React from "react"


const CanvasToolbar = ({ children }) => {
  return (
	<div id="toolBar" className="bg-blue px-8 py-8 text-left text-sm flex flex-row">
	  
		{children}
	</div>
  )
}

export default CanvasToolbar
