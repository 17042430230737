import React from "react"
import Type from "../../components/typography/Type"


const MomentCard = (props) => {	
	
const title = props.title;
const url = props.url;
		
		  return (
			  <>
		  <div className="mx-2 bg-blue/20 rounded-lg h-36 flex items-center">          
			  {/* L3 Heading */}
			  <div className="">
				<div className="px-8">
				  <a href={url}><Type type="h6" nopadding="yes">{title}</Type></a>
				</div>
			  </div>
			</div>
		  </>
	  );
};

export default MomentCard;
