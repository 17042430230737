import React from "react"
import Type from "../../components/typography/Type"


const CapabilityCard = (props) => {	
	
const level = props.level; 	
const children = props.children;
const title = props.title;
const introduction = props.introduction;

const url = props.url;
const iconUrl = props.iconURL;
const resourceIconUrl = props.resourceIconURL;
		
	switch (level) {
		  
		  case "level1":
		  return (
			  <>
		  <div className="p-8 bg-yellow/30 rounded-lg h-full"> 
		  	<div className="flex flex-row mb-4"> 
			{ iconUrl ?
				<div className="w-12 lg:w-14 mr-2">
					<img src={iconUrl} alt={title} className="w-12 lg:w-12"/></div>
				:
				undefined
			}
			<div className="grow ">
				<Type type="h5" nopadding="yes">{title}</Type>
				<Type type="p6" nopadding="yes">{introduction}</Type>
			</div>
			{ url ?
			<div className="flex justify-end w-12 lg:w-14 ml-2">
				<div className="bg-white rounded-xl p-1 w-12 h-12 items-center">
					<img src={resourceIconUrl} alt={title} className="w-full"/>
				</div>
			</div>	
				:
				undefined
			}
				
			  </div>
			{children}
		  </div>
		  </>
	  );
	  case "level2":
			return (
			<div className="bg-white rounded-lg p-8">
			<div className="flex flex-row space-x-8">
				<div className="grow">
			  <Type type="h6" nopadding="yes">{title}</Type>
			  <Type type="p6" nopadding="yes">{introduction}</Type>
				</div>
				<div>
				{ url ?
				<div className="w-12">
				<div className="bg-white rounded-xl p-2">
					<img src={resourceIconUrl} alt={title} className="w-full"/>
				</div>
				</div>	
					:
					undefined
				}
				</div>
			</div>
			  {children}
			</div>
		);
		case "level3":
			  return (
			  <div className="w-full rounded-lg bg-green/10 p-4 flex items-center text-center">
				  <Type type="p6" nopadding="yes">{title}</Type>
				  			</div>
		  );
	  default:
	  return (
		 <div className="w-full rounded-lg bg-green/10 p-4 flex items-center text-center">

		   	<Type type="p6" nopadding="yes">{title}</Type>
		 </div>
	  );
	};	
};

export default CapabilityCard;
