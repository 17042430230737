import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import ProcessCard from "../../components/cards/ProcessCard"
import FeatureCard from "../../components/cards/FeatureCard"
import CapabilityCard from "../../components/cards/CapabilityCard"
import MomentCard from "../../components/cards/MomentCard"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"
import Button from "../../components/controls/Button"


import Toggle from "../../components/controls/Toggle"


import { useState } from "react"



export default function Pages( {data}) {

const [isGrid, toggleGrid] = useState(true);
const [isFeatures, toggleFeatures] = useState(true);
const [isNeeds, toggleNeeds] = useState(true);
const [isProcess, toggleProcess] = useState(true);
const [isCapabilities, toggleCapabilities] = useState(true);

const [isOptions, toggleOptions] = useState(false);

const columnWidth = 320;
const horPadding = 0;
const verPadding = 16;

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (



<div>
 
    <CanvasToolbar>
    <div><a href={data.airtable.data.ProjectPath}><span className="">&lt; Back to {data.airtable.data.ProjectTxt}</span></a></div>
    <div className="grow">
      <div className="flex flex-row justify-end space-x-8 lg:items-center">
        <div><button onClick={() => toggleOptions(!isOptions)} className="">Options</button></div>
        <div className={`${ isOptions ? `block` : `hidden` } flex flex-col lg:flex-row lg:justify-end space-y-2 lg:space-y-0 space-x-0 lg:space-x-8`}>
          <Toggle action="Grid" object={isGrid} switch={toggleGrid} explainer="(or stacked columns)" />
          <Toggle action="Features" object={isFeatures} switch={toggleFeatures} />
          <Toggle action="Needs" object={isNeeds} switch={toggleNeeds} />
          <Toggle action="Value Stream" object={isProcess} switch={toggleProcess} />
          <Toggle action="Capabilities" object={isCapabilities} switch={toggleCapabilities} />
        </div>  
      </div>
    </div>
    </CanvasToolbar>    
    


  
 <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  


<div className="flex flex-row">
  {/* Actors */}
  <div className="max-w-fit lg:h-screen  p-8 bg-yellow-light">
    <div className={`w-full lg:w-[${columnWidth}px]`}>
      
      
      {data.airtable.data.Actors.map((ActorsArray) => (
        <>
       <Type type="h3">{ActorsArray.data.Name}</Type>
           
           <img 
             src={ActorsArray.data.ImageURL}
             alt={ActorsArray.data.Name}
             className="w-1/2 mb-8"
             />

           
           <Type type="p5">{ActorsArray.data.Introduction}</Type>
      


       
    </>
      ))}
      
    </div>
  </div>
  
  

  <div id="JourneyCanvas" className="bg-white p-24 overflow-auto pr-12">
    <div className="flex flex-row space-x-0">
    {data.airtable.data.JourneySteps.map((StepsArray) => (
      <div className={`min-w-[${columnWidth}px]`}>
      
        {StepsArray.data.Products.map((ProductsArray) => (
          <img 
          src={ProductsArray.data.HeroImage}
          alt={ProductsArray.data.Name}
          className="w-full px-8 mb-8 "
          />
          ))}
        
        
        {StepsArray.data.Products.map((ProductsArray) => (
        <div className=" text-center px-8 h-24 overflow-none flex items-center">
         <Type type="h5">{ProductsArray.data.Name}</Type>
        </div>
        ))}
        
         <img 
          src={StepsArray.data.ChannelIcon}
          alt={StepsArray.data.Name}
          className="mx-auto h-12 my-12"
          />
            
          
   
      
      
      
  
      
        <div className="my-8 h-10">
            <div className="bg-blue/30 h-4"/>
            <div className="-mt-7 h-full w-10 bg-yellow text-center mx-auto flex items-center justify-center rounded-full">
              <Type type="h6" nopadding="yes">{StepsArray.data.Order}</Type>
            </div>
        </div>
        
        {StepsArray.data.Products.map((ProductsArray) => (
          <div className=" text-center px-8 h-36 overflow-auto">
           <Type type="p6">{ProductsArray.data.Introduction}</Type>
          </div>
        ))}
        
        {StepsArray.data.Notes
          ?
          <>
        <div className="grid grid-cols-1 gap-4 mt-16">
        {StepsArray.data.Notes.map((NotesArray) => (
          <div className={`rounded-xl bg-${NotesArray.data.NoteColour} p-8 m-4`}>
            <img
              src={NotesArray.data.NoteIcon}
              alt={NotesArray.data.NoteType}
              className="w-8 mb-8"
              />
           <Type type="p6" nopadding="yes">{NotesArray.data.Note}</Type>
          </div>
        ))}
        </div>
        </>
        :
        <Button type="add" title="Add a Note" action="/new/note"/> 

      }
        
        
      </div>
    ))}
    </div>

  </div>
</div>
 </Canvas>

</div>           


    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )} 

  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Framework</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Journeys" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        ProjectPath
        ProjectTxt
        Actors {data {
          Name
          ImageURL
          Introduction
        }}
        JourneySteps { data {
            Name
            Order
            ChannelIcon
            Products {data {
              Name
              HeroImage
              Introduction
              
            }}
            Notes {data {
              Note
              NoteIcon
              NoteColour
            }}
        }}
      }
    }
  }
`
