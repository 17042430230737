import React from "react"
import Type from "../typography/Type"


const Button = (props) => {	
	
const action = props.action; 
const title = props.title;
const type = props.type;	
	

	switch (type) {

		case "add":
			return ( 
	   		<a href={action}>
			   		<button className="flex items-center justify-center border border-grey-light hover:border-grey text-grey/60 hover:text-grey px-4 py-2 rounded-xl text-base">{title}</button>
	   		</a>
			);
		case "addObject":
			return ( 
			   <a href={action}>
				 <button className="border-2 border-white text-white hover:bg-white hover:text-black rounded-lg py-4 px-auto w-full">
					  <Type type="h6" nopadding="yes">{title}</Type>
					  </button>
			   </a>
	
			   
			);
		case "submit":
			return ( 
			<div className="p-4 flex items-center justify-center">
			   <a href="/new/moments">
			   		<button className="bg-black/10 border hover:bg-white text-grey px-8 p-4 rounded-xl ">
			   <Type type="p4" nopadding="yes">Add a Moment</Type></button>
			   </a>
			 </div>
			);
		default: 
			return ( 
				<div className="p-4 flex items-center justify-center">
				   <a href="/new/moments">
						   <button className="bg-black/10 border hover:bg-white text-grey px-8 p-4 rounded-xl ">
				   <Type type="p4" nopadding="yes">Add a Moment</Type></button>
				   </a>
				 </div>
			 );
	  };
};

export default Button;
